<template>
  <div>
    <h1 class="outline--text d-flex">
      Package settings
      <v-spacer></v-spacer>
      <v-btn
        v-if="canManage"
        color="primary"
        small
        rounded
        class="align-self-baseline"
        @click="editItem"
        >New package</v-btn
      >
    </h1>
    <v-row>
      <v-col class="outline--text mt-7" style="max-width: 700px">
        <div>
          <span v-if="!isLoading && this.totalCount > 0">
            <p v-if="this.totalCount > this.currentPage * this.itemsPerPage">
              Showing {{ (this.currentPage - 1) * this.itemsPerPage + 1 }}-{{
                this.currentPage * this.itemsPerPage
              }}
              of {{ this.totalCount }} items
            </p>
            <p v-else>
              Showing {{ (this.currentPage - 1) * this.itemsPerPage + 1 }}-{{
                this.totalCount
              }}
              of {{ this.totalCount }} items
            </p>
          </span>
        </div>
      </v-col>
      <v-col>
        <div class="d-flex justify-space-between">
          <v-spacer></v-spacer>
          <v-pagination
            v-if="showPages && pageCount > 1"
            class="my-3"
            v-model="pagination.page"
            :length="pageCount"
            :total-visible="7"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
    <v-data-table
      id="itemsTable"
      :headers="headers"
      :loading="isLoading"
      :items="packages"
      disable-pagination
      disable-filtering
      disable-sort
      hide-default-footer
    >
      <template v-slot:item.price="{ item }"> {{ item.price }} UAH </template>
      <template v-slot:item.name="{ item }">
        <div class="d-flex text-no-wrap">
          {{ item.name }}
          <v-chip v-if="item.isTrial" color="primary" small class="ml-2"
            >Trial</v-chip
          >
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <div v-if="canManage" class="d-flex">
          <v-btn x-small icon @click="editItem(item)">
            <v-icon>mdi-pencil-outline</v-icon>
          </v-btn>
          <v-tooltip v-if="item.isTrial" left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-small
                icon
                class="ml-3"
                v-bind="attrs"
                v-on="on"
                color="grey lighten-2"
              >
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
            </template>
            <span>Can not delete trial package</span>
          </v-tooltip>
          <v-btn
            v-else
            x-small
            icon
            class="ml-3"
            @click="confirmDeleteItem(item)"
          >
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </div>
      </template>
      <template v-slot:item.accounts="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <router-link
                :is="canSeeAccounts ? 'router-link' : 'span'"
                :to="{
                  name: 'AccountListPage',
                  query: { currentPackage: item.id },
                }"
              >
                <span class="text-green">{{ item.currentSubscription }}</span> /
                <span class="text-purple">{{ item.nextSubscription }}</span>
              </router-link>
            </span>
          </template>
          <div>
            Current subscription: {{ item.currentSubscription }}<br />
            Next subscription: {{ item.nextSubscription }}
          </div>
        </v-tooltip>
      </template>
      <template v-slot:item.isVisible="{ item }">
        <v-chip :color="item.isVisible ? 'green' : 'grey'" outlined small>{{
          item.isVisible ? "Visible" : "Invisible"
        }}</v-chip>
      </template>
    </v-data-table>
    <v-dialog v-if="selectedItem" v-model="deleteDialog" max-width="450px">
      <v-card>
        <v-card-title
          class="headline primary lighten-1 white--text justify-space-between py-1"
        >
          Delete confirmation
          <v-btn icon color="white" @click="deleteDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-progress-linear
          v-if="isLoading"
          indeterminate
          color="primary"
        ></v-progress-linear>
        <v-card-text class="mt-6">
          Are you sure you want to delete {{ selectedItem.name }} package?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="deleteDialog = false">Close</v-btn>
          <v-btn color="primary" text @click="deleteItem(selectedItem)">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editDialog" max-width="700px">
      <subscription-package-form
        v-if="editDialog"
        :packageItem="selectedItem"
        @close="closeEditDialog"
        @submitted="
          fetchItems();
          closeEditDialog();
        "
      ></subscription-package-form>
    </v-dialog>
  </div>
</template>

<script>
import {
  FETCH_PACKAGES,
  DELETE_PACKAGE,
} from "@/store/modules/subscriptionPackage/actions";
import { mapGetters, mapState } from "vuex";
import SubscriptionPackageForm from "@/views/subscriptionPackage/item/SubscriptionPackageForm";

import acl from "@/mixin/acl";
import {
  RESOURCE_SUBSCRIPTION_PACKAGES_MANAGEMENT,
  RESOURCE_ACCOUNTS,
} from "@/store/modules/admin/getters";
export default {
  name: "SubscriptionListPage",
  mixins: [acl],
  components: { SubscriptionPackageForm },
  data() {
    return {
      isLoading: false,
      showPages: false,

      headers: [
        {
          text: "Package",
          value: "name",
          width: "100",
        },
        {
          text: "Description",
          value: "description",
        },
        {
          text: "Price",
          value: "price",
        },
        {
          text: "Billing Period",
          value: "billingPeriod",
        },
        {
          text: "Status",
          value: "isVisible",
        },
        {
          text: "Update date",
          value: "updatedAt",
        },
        {
          text: "Аccounts",
          value: "accounts",
        },
        {
          width: "50",
          text: "",
          value: "actions",
        },
      ],
      deleteDialog: false,
      editDialog: false,
      selectedItem: null,
    };
  },
  computed: {
    ...mapGetters("subscriptionPackage", [
      "packageList",
      "totalCount",
      "currentPage",
      "pageCount",
      "offset",
    ]),
    ...mapState("subscriptionPackage", ["pagination"]),
    canManage: function () {
      return this.hasAccess([RESOURCE_SUBSCRIPTION_PACKAGES_MANAGEMENT]);
    },
    canSeeAccounts: function () {
      return this.hasAccess([RESOURCE_ACCOUNTS]);
    },
    itemsPerPage: function () {
      return this.pagination.rowPerPage;
    },
    packages: function () {
      if (this.isLoading) {
        return [];
      }
      return this.packageList;
    },
  },
  watch: {
    "pagination.rowPerPage": function () {
      this.pagination.page = 1;
    },
  },
  methods: {
    fetchItems: function () {
      const vm = this;
      vm.isLoading = true;
      vm.$store.dispatch(`subscriptionPackage/${FETCH_PACKAGES}`).then(
        () => {
          vm.isLoading = false;
          vm.showPages = true;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    confirmDeleteItem: function (item) {
      this.selectedItem = item;
      this.deleteDialog = true;
    },
    deleteItem: function (item) {
      this.isLoading = true;
      this.$store
        .dispatch("subscriptionPackage/" + DELETE_PACKAGE, item)
        .then(
          () => {
            this.isLoading = false;
            this.selectedItem = null;
            this.deleteDialog = false;
            this.fetchItems();
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
    editItem: function (item) {
      this.selectedItem = item;
      this.editDialog = true;
    },
    closeEditDialog: function () {
      this.selectedItem = null;
      this.editDialog = false;
    },
  },
  beforeMount() {
    this.fetchItems();
  },
};
</script>

<style scoped></style>
